// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,600;6..12,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  box-sizing: border-box;
  --root-color: #6b4eff;
  --gray-color: #4f5665;
  --orange-color: #f89500;
  --white-color: #ffffff;
  --hover: #ff8b03;
  --active-click: #f17400;
  font-family: "Nunito Sans", sans-serif;
}
.App {
  min-width: 100%;
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,sCAAsC;AACxC;AACA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,600;6..12,700&display=swap\");\n\n:root {\n  box-sizing: border-box;\n  --root-color: #6b4eff;\n  --gray-color: #4f5665;\n  --orange-color: #f89500;\n  --white-color: #ffffff;\n  --hover: #ff8b03;\n  --active-click: #f17400;\n  font-family: \"Nunito Sans\", sans-serif;\n}\n.App {\n  min-width: 100%;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
