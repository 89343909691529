// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Download_download__C87oB {
  font-size: 1.25rem;
  font-weight: 700;
  border-radius: 30px;
  background: var(--orange-color);
  padding: 10px 28px;
  color: white;
  border: none;
  font-family: Nunito Sans;
  cursor: pointer;
}
.Download_download__C87oB:hover {
  background-color: var(--hover);
}
.Download_download__C87oB:active {
  background-color: var(--active-click);
}

@media (max-width: 1280px) {
  .Download_download__C87oB {
    font-size: 1rem;
    padding: 7px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Home_components/Download/Download.module.scss","webpack://./src/components/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,+BAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;EACA,eAAA;AADF;AAGE;EACA,8BAAA;AADF;AAGA;EACE,qCAAA;AADF;;ACTI;EDeF;IACE,eAAA;IACA,iBAAA;EAFF;AACF","sourcesContent":["@import '../../scss/variable';\n\n.download {\n  font-size: 1.25rem;\n  font-weight: 700;\n  border-radius: 30px;\n  background: var(--orange-color);\n  padding: 10px 28px;\n  color: white;\n  border: none;\n  font-family: Nunito Sans;\n  cursor: pointer;\n\n  &:hover{\n  background-color: var(--hover);\n}\n&:active{\n  background-color: var(--active-click);\n}\n}\n\n@include desctop{\n  .download{\n    font-size: 1rem;\n    padding: 7px 20px;\n  }\n}","\n@mixin max-desctop(){\n    @media (max-width: 1405px){\n        @content\n    }\n};\n@mixin desctop(){\n    @media (max-width: 1280px){\n        @content\n    }\n};\n@mixin leptop(){\n    @media (max-width: 1024px){\n        @content \n    }\n}; \n@mixin table() {\n    @media only screen \n    and (max-device-width: 768px) \n    and (-webkit-min-device-pixel-ratio: 1) {\n    @content;\n  }\n}\n\n@mixin mobile{\n    @media screen \n    and (max-device-width: 579px) \n    and (-webkit-min-device-pixel-ratio: 1) {\n    @content;\n  }\n}\n@mixin min_mobile{\n    @media screen \n    and (max-device-width: 380px) \n    and (-webkit-min-device-pixel-ratio: 1) {\n    @content;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download": `Download_download__C87oB`
};
export default ___CSS_LOADER_EXPORT___;
